import css from './Video.module.scss'
import global from '../../../styles/Global.module.scss'
import {useState, useEffect, useRef} from "react";
import useVisible from "../../../hooks/useVisible";

const Video = ({video}) => {

    const text = ['воздух', 'вид' , 'стиль']

    const [title, setTitle] = useState('воздух')

    //animation
    const ref = useRef(null)
    const {isVisible: inView} = useVisible(ref)
    //

    const [visible, setVisible] = useState(false)

    useEffect(()=> {
            let i = 1
            let timerId = setInterval(() => {
                i === text.length ? i = 0 : ''
                setTitle(text[i])
                i++
            }, 5000)
        if(!inView){
            clearInterval(timerId)
        }
        inView && setVisible(true)
    }, [inView])


    return (
        <section className={css.video} ref={ref}>
            {visible &&
                <>
                    <video autoPlay={true} loop={true} muted="muted" playsInline width="100%" height="100%">
                        <source src={process.env.NEXT_PUBLIC_API_LOCAL_PATH ? `${process.env.NEXT_PUBLIC_API_LOCAL_PATH}${video}` : video} type="video/mp4"/>
                    </video>
                    <div className={`${global.section} ${css.section}`}>
                        <div className={css.text}>MY <span>{title}</span></div>
                    </div>
                </>
            }
        </section>
    )
}

export default Video